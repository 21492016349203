input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type='submit'] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type='submit']:hover {
  background-color: #45a049;
}

.contact-person {
  width: 400px !important;
}

@media screen and (max-width: 950px) {
  .contact-person {
    width: 350px !important;
  }
}

input[type='text'],
input[type='email'] {
  height: 55px;
}
