.css-r40f8v-MuiTypography-root {
  line-height: 25px !important;
}

.sc-iIPllB,
.iRymlH,
.sc-afnQL,
.hwANuT,
.sc-kgUAyh,
.dFlTte,
.sc-cCsOjp,
.ihRwwX {
  width: 100% !important;
}
