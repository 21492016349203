.footer {
  width: 100%;
  height: 120px;
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.copyright {
  color: white;
  padding-top: 15px;
  font-size: 20px;
  color: darkgrey;
  width: 80%;
  text-align: center;
  /* margin-left: -30px !important; */
  font-weight: bold;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer-info {
  display: flex;
  width: 40%;
  justify-content: center;
  margin-bottom: 15px;
  margin: auto;
}

.mu {
  margin-top: 10px;
}

@media screen and (max-width: 450px) {
  .copyright {
    font-size: 17px;
  }
}
